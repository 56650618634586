import React from "react";
import Form from "./Form.js";
// import LandingPage from "./components/LandingPage.jsx";
const App = () => (
  <>
    {/* <LandingPage/> */}
    <Form />
  </>
);

export default App;
