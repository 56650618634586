import React from "react";
const Loader = () => {
  return (
    <>
      <div className="content">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div
        style={{
          background: "#101010",
          position: "absolute",
          zIndex: "auto",
          width: "46vh",
          left: "38vw",
          bottom: "41px",
        }}
        className="message"
      >
        <p className="message__line">Loading…</p>
        <p className="message__line">Sorting out the pieces…</p>
        <p className="message__line">Assembling one brick at a time…</p>
        <p className="message__line">Following every step…</p>
        <p className="message__line">
          Being very careful not to step on the pieces…
        </p>
        <p className="message__line">Trying not to get finger blisters…</p>
        <p className="message__line">
          Might take longer to build than the Death Star…
        </p>
        <p className="message__line">Wrapping it up…</p>
      </div>
    </>
  );
};

export default Loader;
