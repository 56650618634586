import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import Loader from "./Loader";
import "./App.css";

const UrlForm = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const webUrl = process.env.REACT_APP_WEB_URL;
  console.log({ apiUrl }, { webUrl });

  const [url, setUrl] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isHomepage, setIsHomepage] = useState(false);
  const [email, setEmail] = useState("");
  const [isModal, setIsModal] = useState(false);

  const isValidHttpUrl = (string) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    const regex = /^(https?):\/\/([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)(\/.*)?$/;
    return regex.test(string) && ["http:", "https:"].includes(url.protocol);
  };

  const isValidEmailfn = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // List of known disposable email domains (you can expand this list as needed)
    const disposableEmailDomains = [
      "mailinator.com",
      "guerrillamail.com",
      "10minutemail.com",
      "temp-mail.org",
      "tempmail.com",
      "throwawaymail.com",
      "disposablemail.com",
      "yopmail.com",
      "example.com",
    ];

    // Check if the email format is valid
    if (!regex.test(email)) {
      return false;
    }

    // Extract domain from the email
    const domain = email.split("@")[1];

    // Check if the domain is a disposable email domain
    if (disposableEmailDomains.includes(domain)) {
      return false;
    }

    return true;
  };

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    if (!isValidEmailfn(email)) {
      setIsValidEmail(false);
      return;
    }
    setIsValidEmail(true);

    try {
      const response = await fetch(`${apiUrl}/api/submit-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, url }),
      });

      const result = await response.json();
      console.log(result);
      if (result.error) {
        throw new Error(result.error);
      }
      setIsModal(false);
      handleSubmit(event);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isValidHttpUrl(url)) {
      setIsValidUrl(false);
      return;
    }
    if (!email) {
      setIsModal(true);
      return;
    }
    setIsLoading(true);
    setIsValidUrl(true); // Reset the URL validation state

    try {
      const templates = ["index-new.php", "index-new2.php"];

      // Generate a random index based on the length of the array
      const randomIndex =
        crypto.getRandomValues(new Uint32Array(1))[0] % templates.length;

      const randomTemplate = templates[randomIndex];

      const response = await fetch(
        `${apiUrl}/api/process-url${isHomepage ? "2" : ""}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ url }),
        }
      );

      const result = await response.json();
      console.log(result);

      const lines = result.output.split("\n");

      // Filter out empty strings to avoid issues with extra newlines
      const nonEmptyLines = lines.filter((line) => line.trim() !== "");

      // Get the last non-empty line (the timestamp)
      const lastValue = nonEmptyLines[nonEmptyLines.length - 1];

      console.log("Last value (timestamp):", lastValue);
      if (!isHomepage) {
        window.location.href = `${webUrl}/${randomTemplate}?uuid=${lastValue}`;
      } else {
        window.location.href = `${webUrl}/page.php?uuid=${lastValue}`;
      }
    } catch (error) {
      setResponseMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    );
  }
  return (
    <div
      className="box-root flex-flex flex-direction--column"
      style={{ minHeight: "100vh", flexGrow: 1 }}
    >
      <div className="loginbackground box-background--white padding-top--64">
        <div className="loginbackground-gridContainer">
          <div
            className="box-root flex-flex"
            style={{ gridArea: "top / start / 8 / end" }}
          >
            <div
              className="box-root"
              style={{
                backgroundImage:
                  "linear-gradient(white 0%, rgb(247, 250, 252) 33%)",
                flexGrow: 1,
              }}
            />
          </div>
          <div
            className="box-root flex-flex"
            style={{ gridArea: "4 / 2 / auto / 5" }}
          >
            <div
              className="box-root box-divider--light-all-2 animationLeftRight tans3s"
              style={{ flexGrow: 1 }}
            />
          </div>
          <div
            className="box-root flex-flex"
            style={{ gridArea: "6 / start / auto / 2" }}
          >
            <div
              className="box-root box-background--blue800"
              style={{ flexGrow: 1 }}
            />
          </div>
          <div
            className="box-root flex-flex"
            style={{ gridArea: "7 / start / auto / 4" }}
          >
            <div
              className="box-root box-background--blue animationLeftRight"
              style={{ flexGrow: 1 }}
            />
          </div>
          <div
            className="box-root flex-flex"
            style={{ gridArea: "8 / 4 / auto / 6" }}
          >
            <div
              className="box-root box-background--gray100 animationLeftRight tans3s"
              style={{ flexGrow: 1 }}
            />
          </div>
          <div
            className="box-root flex-flex"
            style={{ gridArea: "2 / 15 / auto / end" }}
          >
            <div
              className="box-root box-background--cyan200 animationRightLeft tans4s"
              style={{ flexGrow: 1 }}
            />
          </div>
          <div
            className="box-root flex-flex"
            style={{ gridArea: "3 / 14 / auto / end" }}
          >
            <div
              className="box-root box-background--blue animationRightLeft"
              style={{ flexGrow: 1 }}
            />
          </div>
          <div
            className="box-root flex-flex"
            style={{ gridArea: "4 / 17 / auto / 20" }}
          >
            <div
              className="box-root box-background--gray100 animationRightLeft tans4s"
              style={{ flexGrow: 1 }}
            />
          </div>
          <div
            className="box-root flex-flex"
            style={{ gridArea: "5 / 14 / auto / 17" }}
          >
            <div
              className="box-root box-divider--light-all-2 animationRightLeft tans3s"
              style={{ flexGrow: 1 }}
            />
          </div>
        </div>
      </div>

      <div
        className="box-root padding-top--24 flex-flex flex-direction--column"
        style={{ flexGrow: 1, zIndex: 9, justifyContent: "center" }}
      >
        <div className="box-root padding-top--48 padding-bottom--24 flex-flex flex-justifyContent--center">
          <h1>
            <a href="http://blog.stackfindover.com/" rel="dofollow">
              Ai-redesign
            </a>
          </h1>
        </div>

        <div className="formbg-outer">
          <div className="formbg">
            <div className="formbg-inner padding-horizontal--48">
              <span className="padding-bottom--15">
                Redesign your website with AI
              </span>
              <form id="stripe-login" onSubmit={handleSubmit}>
                <div className="field padding-bottom--24">
                  <label htmlFor="url">Enter URL</label>
                  <input
                    type="url"
                    name="url"
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    style={{ borderColor: isValidUrl ? "#ccc" : "red" }}
                  />
                  {!isValidUrl && (
                    <div style={{ color: "red" }}>
                      Please enter a valid URL with a domain extension.
                    </div>
                  )}
                </div>
                <label className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    checked={isHomepage}
                    onChange={(e) => setIsHomepage(e.target.checked)}
                  />
                  <div className="checkmark">
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path
                        d="M20 6L9 17L4 12"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <span className="label">Homepage</span>
                </label>
                <label className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    checked={!isHomepage}
                    onChange={(e) => setIsHomepage(!e.target.checked)}
                  />
                  <div className="checkmark">
                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path
                        d="M20 6L9 17L4 12"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <span className="label">Product Page</span>
                </label>
                <div className="field padding-bottom--24">
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    disabled={isLoading}
                    style={{ fontWeight: "bold" }}
                  >
                    Submit
                  </Button>
                </div>
                {responseMessage && (
                  <Alert
                    className="mt-0"
                    variant={
                      responseMessage.startsWith("Error") ? "danger" : "success"
                    }
                  >
                    {responseMessage}
                  </Alert>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal show={isModal} onHide={() => setIsModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter your Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="field padding-bottom--24">
            <label htmlFor="email">Enter URL</label>
            <input
              type="email"
              name="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                borderColor: isValidEmail ? "#ccc" : "red",
                border: "1px solid ",
              }}
            />
            {!isValidEmail && (
              <div style={{ color: "red" }}>Please enter a valid email.</div>
            )}
          </div>
          <Button onClick={handleEmailSubmit} variant="primary">
            Submit Email
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UrlForm;
